<template>
    <div class="hidden">
        <div class="door">
            <img class="logo" src="../assets/logo.png" alt="cieszynianka" @click="toCosmos">
            <div class="left wingdoor" @click="openDoor"></div>
            <div class="right wingdoor" @click="openDoor"></div>
        </div>
        <button class="formButton" @click="tip">CLICK</button>
        <input type="text" placeholder="Login" v-model="login">
        <input type="text" placeholder="Password" v-model="password">
        <button class="formButton" @click="loginMethod">LOGIN</button>
    </div>
    <div class="shopContainer">
        <div class="shopNavbar">
            <div class="videoContainer">
                <video controls class="video" v-show="isFaceHacking" id="faceHacking">
                    <source src="../assets/malysz/facehaking.mp4" type="video/mp4">
                </video>
            </div>
            <div class="buttonsContainer">
                <button class="buttonsContainer__button" @click="faceHacking">nie</button>
                <button class="buttonsContainer__button" @click="turnAround">wiad</button>
                <button class="buttonsContainer__button" @click="turnNormal">omo</button>
                <a class="buttonsContainer__button" href="https://plug.e6yart.pl/">policji</a>
            </div>
        </div>
        <div class="contentContainer">
            <ShopProduct :product="product" v-for="(product, index) in sampleProducts"/>
        </div>
    </div>
</template>

<script>
import ShopProduct from '../components/ShopProduct.vue';

    export default {
        name: 'Shop',

        data() {
            return {
                isFaceHacking: false,
                login: "",
                password: "",
            }
        },

        components: {
            ShopProduct,
        },

        computed: {
            sampleProducts() {
                return this.$store.state.sampleProducts;
            },
        },

        methods: {
            faceHacking() {
                let faceHacking = document.querySelector('#faceHacking');
                this.isFaceHacking = !this.isFaceHacking;
                faceHacking.play();

                if(this.isFaceHacking == false) {
                    faceHacking.pause();
                }
            },

            turnAround() {
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-180deg)", null);
                }, 500);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(90deg)", null);
                }, 550);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(180deg)", null);
                }, 600);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-90deg)", null);
                }, 650);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-180deg)", null);
                }, 700);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(90deg)", null);
                }, 750);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(180deg)", null);
                }, 800);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-90deg)", null);
                }, 850);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-180deg)", null);
                }, 900);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(90deg)", null);
                }, 950);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-180deg)", null);
                }, 1000);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(90deg)", null);
                }, 1050);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(180deg)", null);
                }, 1100);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-90deg)", null);
                }, 1150);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-180deg)", null);
                }, 1200);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(90deg)", null);
                }, 1250);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(180deg)", null);
                }, 1300);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-90deg)", null);
                }, 1350);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(-180deg)", null);
                }, 1400);
                setTimeout(() => {
                    document.body.style.setProperty("-webkit-transform", "rotate(90deg)", null);
                    document.body.style.setProperty("position", "relative", null);
                    document.body.style.setProperty("left", "-25%", null);
                }, 1450);
            },
            
            turnNormal() {
                document.body.style.setProperty("-webkit-transform", "rotate(180deg)", null);
                document.body.style.setProperty("position", "static", null);
            },

            tip() {
                alert('tip: ctrl+shift+i -> console')
                console.log('password: niewiadomo, login: niewiadomo')
            },

            loginMethod() {
                let door = document.querySelector('.door');

                if(this.login == "niewiadomo" && this.password == "niewiadomo") {
                    door.style.display = 'flex';
                }
            },

            openDoor() {
                let leftDoor = document.querySelector('.left');
                let rightDoor = document.querySelector('.right');
                leftDoor.style.left = "-25%";
                rightDoor.style.right = "-25%";
            },

            toCosmos() {
                window.location.href = 'https://cosmos.e6yart.pl/';
            }
        },
    }
</script>


<style scoped lang="scss">
    .logo {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .door {
        justify-content: center;
        display: none;
        position: relative;
    }
    .wingdoor {
        height: 20rem;
        width: 100%;
        background-color: black;
        animation: color 1s infinite;
        cursor: pointer;
        position: relative;
    }

    .left {
        left: 0;
        transition: all 2s ease-in-out;
    }
    .right {
        right: 0;
        transition: all 2s ease-in-out;
    }
    
  .hidden {
    position: absolute;
    left: 50%;
    top: -50%;
    rotate: -90deg;
    display: flex;
    flex-direction: column;
  }

    @font-face {
        font-family: "8bit-Regular";
        src: url("../assets/fonts/8bit/8bitOperatorPlus-Regular.ttf");
        font-family: "8bit-Bold";
        src: url("../assets/fonts/8bit/8bitOperatorPlus-Bold.ttf");
    }

    html {
        background-color: #008080;
    }

    body {
        position: absolute;
    }

    .shopContainer {
        margin-top: 8rem;
    }

    .shopNavbar {
        background-color: #c0c0c0;
        padding: 2rem;
        border-bottom: 6px solid #818181;
        border-right: 6px solid #929292;
        border-top: 6px solid #e4e4e4;
        border-left: 6px solid #d1d1d1;
    }
    .buttonsContainer {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        max-width: 60rem;
        margin: 0 auto;

        &__button {
            padding: 1.6rem;
            text-decoration: none;
            color: white;
            border-bottom: 4px solid #818181;
            border-right: 4px solid #929292;
            border-top: 4px solid #e4e4e4;
            border-left: 4px solid #d1d1d1;
            font-size: 1.4rem;
            font-family: "8bit-Bold";
            background-color: transparent;
            cursor: pointer;

            &:active {
            outline: none;
            border-bottom: 4px solid #e4e4e4;
            border-right: 4px solid #d1d1d1;
            border-top: 4px solid #818181;
            border-left: 4px solid #929292;
            font-size: 1.2rem;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .contentContainer {
        background-color: #008080;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-template-rows: auto;
        justify-items: center;
        padding: 2rem;
        gap: 1rem;
    }

    .video {
        width: 50%;
    }

    .videoContainer {
        text-align: center;
    }

    input {
        width: 100%;
        padding: .5rem;
        outline: none;
        font-weight: bold;
        background-color: magenta;
        color: white;
        font-family: "Comfortaa", sans-serif;
        border: 1px solid transparent;
        font-weight: bold;
        margin-bottom: 5px;

        &::placeholder {
            font-family: "Comfortaa", sans-serif;
            color: white;
            font-weight: bold;
        }
    }

    .formButton {
            padding: .5rem 2rem;
            cursor: pointer;
            width: 250px;
            background: black;
            font-weight: bold;
            font-size: 1rem;
            border: 1px solid transparent;
            outline: none;
            transition: all .2s ease-in-out;
            color: white;
            font-family: 'Comfortaa', sans-serif;
            margin-bottom: 5px;

            &:hover {
                animation: color 1.5s infinite;
            }

            &:focus {
                animation: color 1.5s infinite;
            }

        }
</style>