<template>
    <div class='scrolling-component' ref="scrollComponent">
        <Infinity 
            v-for='(post, index) in posts'
            :post="post"
            :key="index"
        />
    </div>
</template>

<script>
import getPosts from '../get-post';
import Infinity from './Infinity.vue';
import { ref, onMounted, onUnmounted } from 'vue';

export default {
    name: 'ListComponent',
    components: {
        Infinity
    },

        setup() {
            const posts = ref(getPosts(1));
            const scrollComponent = ref(null);

            const loadMorePosts = () => {
                let newPosts = getPosts(1);
                posts.value.push(...newPosts);
            };

            onMounted(() => {
                window.addEventListener("scroll", handleScroll)
            });

            // onUnmounted(() => {
            //     window.addEventListener("scroll", handleScroll)
            // });

            const handleScroll = () => {
                let element = scrollComponent.value;
                if(element.getBoundingClientRect().bottom < window.innerHeight) {
                    loadMorePosts();
                };
            };

            return {
                posts,
                scrollComponent
            };
    }


}
</script>

<style scoped lang="scss">


</style>
