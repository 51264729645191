<template>
    <div v-for="image in infinity" :key="image.url">
        <img :src="getImgUrl('malysz', image.url)" class="introInfinityScroll">
    </div>
</template>

<script>
export default {
    name: 'Infinity',
    data() {
        return {
            infinity: [
                { url: 'infinity.jpg', alt: 'infinity scroll nie wiesz co jest grane' },
            ]
        }
    },

    props: {
        post: String
    },

    methods: {
    getImgUrl: function (folder, imagePath) {
            return require('@/assets/' + folder + '/' + imagePath);
        },
    },

    mounted() {
        let introInfinityScroll = document.querySelectorAll('.introInfinityScroll');

        const getRandomNumber = () => { 
            return (Math.floor(Math.random() * (100 - 10)) + 10);
        };

        window.addEventListener("scroll", () => {
            introInfinityScroll.forEach((e) => {
                e.style.filter = `invert(${getRandomNumber()}%)`;
            })
        })
    }

}
</script>

<style scoped lang="scss">
    .introInfinityScroll {
        width: 100%;
        margin-top: -1rem;
        filter: invert(0);
    }

</style>>
