<template>
  <div class="blogContainer">
   <a class="niewiadomoep" target="_blank" href="https://www.youtube.com/@bro.ski.420/videos">Nie wiadomo EP</a>
   <a class="niewiadomoep" target="_blank" href="https://www.youtube.com/@bro.ski.420/videos">Nie wiadomo EP</a>
   <a class="niewiadomoep" target="_blank" href="https://www.youtube.com/@bro.ski.420/videos">Nie wiadomo EP</a>
   <a class="niewiadomoep" target="_blank" href="https://www.youtube.com/@bro.ski.420/videos">Nie wiadomo EP</a>
   <a class="niewiadomoep" target="_blank" href="https://www.youtube.com/@bro.ski.420/videos">Nie wiadomo EP</a>
   <a class="niewiadomoep" target="_blank" href="https://www.youtube.com/@bro.ski.420/videos">Nie wiadomo EP</a>
    <BlogNavigation v-if="isLogged" />
    <BlogPost :post="post" v-for="(post, index) in sampleBlogPost" :key="index" />
  </div>
</template>

<script>
import BlogNavigation from '../components/BlogNavigation.vue';
import BlogPost from '../components/BlogPost.vue';

export default {
    name: 'Blog', 

    components: {
      BlogNavigation,
      BlogPost,
    },

    data() {
      return {
        isLogged: true,
        
      };
    },
    
    computed: {
      sampleBlogPost() {
        return this.$store.state.sampleBlogPost;
      },
    },
};
</script>

<style scoped lang="scss">
  .blogContainer {
    margin-top: 8rem;
  }

  .niewiadomoep {
    font-size: 1.8rem;
    color: red; 
    background: pink;
    z-index: 999999;
    position: relative;
    animation: color 5s infinite linear; 
  }

  .niewiadomoep:hover {
    color: pink; 
    background: red;
    font-weight: bold;
  }

  @keyframes color {
     from { background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100% ); left: -100%}
     to { background: pink; left: 100% }
  }
</style>