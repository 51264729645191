let names = ['']

const getPosts = (number) => {
    let ret = [];

    for(let i = 0; i < number; i++) {
        ret.push({
            author: names[i % names.length],
        })
    }

    return ret;
}

export default getPosts;