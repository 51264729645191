<template>
    <a :href="`${product.productLinkContainer}`">
        <div class="contentContainer__product">
            <a :href="`${product.productLinkPhoto}`">
                <div class="gallery__content" >
                    <img class="contentContainer__product--photo" :src="require(`../assets/${product.productFolder}/${product.productPhoto}.jpg`)" :alt="chujwdupepolicji">
                </div>
            </a>
            <a class="contentContainer__product--button" :href="`${product.productLinkButton}`">Kup</a>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'ShopProduct',
        props: ['product'],
    }
    
</script>

<style scoped lang="scss">

.contentContainer {
        &__product {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 15rem;
            border-bottom: 4px solid #818181;
            border-right: 4px solid #929292;
            border-top: 4px solid #e4e4e4;
            border-left: 4px solid #d1d1d1;
            animation: color 1.5s infinite reverse;

            &--button {
                padding: 1rem 2rem;
                text-decoration: none;
                color: white;
                border-bottom: 4px solid #818181;
                border-right: 4px solid #929292;
                border-top: 4px solid #e4e4e4;
                border-left: 4px solid #d1d1d1;
                font-size: 1.2rem;
                font-family: "8bit-Bold";
                margin-top: 1rem;

                &:hover {
                    animation: color 1.5s infinite;
                }
            }

            &--photo {
                max-width: 12rem;
            }
        }
    }

        @keyframes color {
            0% {
                color:#FF69B4;
                background-color: #9400D3;
                border: 4px solid #FF69B4;
            }
            14% {
                color:#FE2020;
                background-color: #4B0082;
                border: 4px solid #FE2020;
            }
            28% {
                color:#FFA500;
                background-color: #40E0D0;
                border: 4px solid #FFA500;
            }
            42% {
                color:#FFFF00;
                background-color: #008000;
                border: 4px solid #FFFF00;
            }
            56% {
                color:#008000;
                background-color: #FFFF00;
                border: 4px solid #008000;
            }
            70% {
                color:#40E0D0;
                background-color: #FFA500;
                border: 4px solid #40E0D0;
            }
            84% {
                color:#4B0082;
                background-color: #FE2020;
                border: 4px solid #4B0082;
            }
            100% {
                color:#9400D3;
                background-color: #FF69B4;
                border: 4px solid #9400D3;
            }
        }
</style>