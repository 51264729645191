<template>
    <div class="musicContainer">
        <p class="musicContainer__currentTitle">{{ current.artist }} - <span>{{ current.title }}</span></p>
        <div class="musicContainer__controls">
            <button class="prev btn-action" @click="prev">Pre6y</button>
            <button class="play btn-toggle" @click="play" v-if="!isPlaying">
                <img :src="getImgUrl(image[1].url)">
            </button>
            <button class="pause btn-toggle" v-else @click="pause">
                <img :src="getImgUrl(image[0].url)">
            </button>
            <button class="next btn-action" @click="next">Ne6y</button>
        </div>
        <div class="playlist">
            <button v-for="song in songs" :key="song.src" @click="play(song)">
                {{ song.title }} - {{ song.artist }}
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Music',

    data() {
        return {
            image: [
                { url: 'kaseta.png' },
                { url: 'kaseta_off.png' },
            ],
            current: {},
            index: 0,
            isPlaying: false,
            songs: [
                // {
                //     title: 'net.art',
                //     artist: 'net.art',
                //     src: require('../assets/music/aet.art-like-Tom.ek.mp3')
                // }, 
                // {
                //     title: 'przedrumowany',
                //     artist: 'Bro.Ski',
                //     src: require('../assets/music/PRZEDRUMOWANY (1).mp3')
                // }, 
                // {
                //     title: 'przedrumowany',
                //     artist: 'Bro.Ski',
                //     src: require('../assets/music/PRZEDRUMOWANY (1).mp3')
                // }, 
                {
                    title: 'MyYouth',
                    artist: 'Kszywy',
                    src: require('../assets/music/MyYouth.mp3')
                }, 
                {
                    title: 'Macintosh',
                    artist: 'Macintosh',
                    src: require('../assets/music/Macintosh.mp3')
                }, 
                {
                    title: 'Resonance',
                    artist: 'Resonance',
                    src: require('../assets/music/Resonance.mp3')
                }, 
                //  {
                //     title: '1',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki1.mp3')
                // },                {
                //     title: '2',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki2.mp3')
                // },                {
                //     title: '4',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki3.mp3')
                // },                {
                //     title: '5',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki4.mp3')
                // },                {
                //     title: '6',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki5.mp3')
                // },                {
                //     title: '7',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki6.mp3')
                // },                {
                //     title: '8',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki7.mp3')
                // },                {
                //     title: '9',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki8.mp3')
                // },                {
                //     title: '10',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki9.mp3')
                // },                {
                //     title: '11',
                //     artist: 'Bro808Ski',
                //     src: require('../assets/music/BroSki10.mp3')
                // },
            ],
            player: new Audio(),
        }
    },

    methods: {
        getImgUrl: function (imagePath) {
            return require('@/assets/music/' + imagePath);
        },
        play(song) {
            if(typeof song.src != "undefined") {
                this.current = song;
                this.player.src = this.current.src;
            };
            this.player.play();
            this.isPlaying = true;
            this.player.onended = () => {
                this.next();
            };
        },
        pause() {
            this.player.pause();
            this.isPlaying = false; 
        },
        next() {
            this.index++;
            if(this.index > this.songs.length - 1) {
                this.index = 0;
            }

            this.current = this.songs[this.index];
            this.play(this.current);
        },
        prev() {
            this.index--;
            if(this.index < 0) {
                this.index = this.songs.length - 1;
            }

            this.current = this.songs[this.index];
            this.play(this.current);
        },
    },

    created() {
        this.current = this.songs[this.index];
        this.player.src = this.current.src;
    },
}
</script>
<style scoped lang="scss">
    .musicContainer {
        background: linear-gradient(
            90deg,
            rgba(255, 0, 0, 1) 0%,
            rgba(255, 154, 0, 1) 10%,
            rgba(208, 222, 33, 1) 20%,
            rgba(79, 220, 74, 1) 30%,
            rgba(63, 218, 216, 1) 40%,
            rgba(47, 201, 226, 1) 50%,
            rgba(28, 127, 238, 1) 60%,
            rgba(95, 21, 242, 1) 70%,
            rgba(186, 12, 248, 1) 80%,
            rgba(251, 7, 217, 1) 90%,
            rgba(255, 0, 0, 1) 100%
        );
        position: fixed;
        bottom: 0;
        background-color: white;
        width: 100%;
        padding-top: .1rem;
        padding-bottom: 1.6rem;
        opacity: .75;

        &__currentTitle {
            color: white;
            font-size: 1rem;
            text-align: center;
            padding: 1rem;
        }

        &__controls {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }

    .btn-toggle {
        border: 0;
        background-color: transparent;
        cursor: pointer; 

        img {
            width: 2rem;
        }
    }

    .playlist {
        display: none;
    }

    .nav-activeMusic {
        background: linear-gradient(
            90deg,
            rgba(255, 0, 0, 1) 0%,
            rgba(255, 154, 0, 1) 10%,
            rgba(208, 222, 33, 1) 20%,
            rgba(79, 220, 74, 1) 30%,
            rgba(63, 218, 216, 1) 40%,
            rgba(47, 201, 226, 1) 50%,
            rgba(28, 127, 238, 1) 60%,
            rgba(95, 21, 242, 1) 70%,
            rgba(186, 12, 248, 1) 80%,
            rgba(251, 7, 217, 1) 90%,
            rgba(255, 0, 0, 1) 100%
        );
    }

    .btn-action {
        border: 0;
        color: white;
        font-size: 1rem;
        background-color: transparent;
        cursor: pointer;
    }

    @media(min-width: 800px) {
        .musicContainer {
            padding-bottom: .4rem;

            &__currentTitle {
                font-size: 1.2rem;
            }
        }

        .btn-toggle {
            img {
                width: 3rem;
            }
        }

        .btn-action {
            font-size: 1.2rem;
        }
    }
</style>