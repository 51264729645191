<template>
    <div class="kaoContainer">
        <img class="kao" :src="getImgUrl('malysz', image[0].url)" :alt="image.alt" @click="kaoClick">
        <a href='https://www.wardrobe.e6yart.pl'>
            <img class="tipContainer-cloud" :src="getImgUrl('malysz', image[2].url)" :alt="image.alt" v-if="isActive">
        </a>
        <a href='https:/www.pill.e6yart.pl'>
            <img class="tipContainer" :src="getImgUrl('malysz', image[1].url)" :alt="image.alt" v-if="isActive">
        </a>
    </div> 
</template>
<script>
export default {
    name: 'Kao',

    data() {
        return {
           image: [
                { url: 'kao.png', alt: 'eyyyyyy.tomaszek' }, 
                { url: 'witamy.png', alt: 'eyyyyyy.lukaszkombinator' }, 
                { url: 'chmurka.png', alt: 'eyyyyyy.tosia' }, 
           ],
           isActive: false,
        }
    },

    methods: {
        getImgUrl: function (folder, imagePath) {
            return require('@/assets/' + folder + '/' + imagePath);
        },

        kaoClick: function() {
            this.isActive = !this.isActive;
            console.log('Eyyyyyy to muvment skurwysynu zapamiętaj Daber krol vol. nie wiadomo');
        }
    },

    created() {

    },

    mounted() {
        let mousePosition;
        let offset = [0,0]; 
        let kao;
        let isDown = false;

        kao = document.querySelector('.kao');

        kao.addEventListener('mousedown', function(e) {
            isDown = true;
            offset = [
                kao.offsetLeft - e.clientX,
                kao.offsetTop - e.clientY
            ];
        }, true);

        document.addEventListener('mouseup', function() {
            isDown = false;
        }, true);

        document.addEventListener('mousemove', function(event) {
            event.preventDefault();
            if (isDown) {
                mousePosition = {
                    x : event.clientX,
                    y : event.clientY
                };
                kao.style.left = (mousePosition.x + offset[0]) + 'px';
                kao.style.top  = (mousePosition.y + offset[1]) + 'px';
                kao.style.width = (mousePosition.y + offset[0]) + 'px';
                kao.style.height = (mousePosition.y + offset[1]) + 'px';
            }
        }, true);
    },
}
</script>
<style scoped lang="scss">
    .kao {
        position: fixed; 
        bottom: 0;
        right: 0; 
        width: 6rem;
        cursor: pointer;
        z-index: 9999;
    }

    .tipContainer {
        display: block;
        position: fixed;
        bottom: 9rem;
        right: 4rem;
        width: 6rem;
        animation: cloud 10s infinite linear; 
    }

    .tipContainer-cloud {
        display: block;
        position: fixed;
        bottom: 7rem;
        right: 0;
        width: 14rem;
        animation: cloud 15s infinite linear; 
    }

    @keyframes cloud {
        from { bottom: 0; right: 100% }
        to { bottom: 100%; right: -100% }
    }

    @media(min-width: 900px) {
        .kao {
            width: 8rem;
        }

        .tipContainer-cloud {
            width: 22rem;
        }

        .tipContainer {
            width: 10rem;
            bottom: 10rem;
            right: 10rem;
        } 
    }
</style>